import React from "react";
import "../../styles/gallery.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="slider-container d-flex">
        <div className="left-slider">
          <button>
            <KeyboardArrowUpIcon />
          </button>
          <h1>Best Photo Studio</h1>
          <h5>A HIGH QUALITY PHOTOGRAPHY</h5>
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo.
          </p>
        </div>
        <div className="right-slider">
          <button>
            <KeyboardArrowDownIcon />
          </button>
          <img />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
